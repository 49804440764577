<template>
    <div class="card-tm">
        <div class="container container-row py-4">
            <div class="row gy-3">
                <div class="col-12">
                    <TopHeading :data="topHeading" />
                </div>
                <div class="col-lg-8 col-12" id="launchpad">
                    <div class="card">
                        <div class="card-body">
                            <div class="row gy-5">
                                <div class="col-12">
                                    <div class="gap-3 d-flex">
                                        <div>
                                            <img :src="response.image" alt="image" class="border rounded-circle token_img"
                                                width="50">
                                        </div>
                                        <div class="w-100">
                                            <div class="d-flex justify-content-between flex-wrap align-items-center mb-1">
                                                <p class="coin_name">{{ response.name }}</p>
                                                <p class="fw-500">Sale End Date: <span class="fw-600">{{ new Date(response.expired_at).toLocaleDateString('en-US',options)}}</span></p>
                                            </div>
                                            <div class="d-flex gap-2 flex-wrap text-capitalize align-items-center">
                                                <a class="badge badge-pill secondary-badge"
                                                    :href="response.whitepaper_link"><i class="fas fa-link"></i>
                                                    Whitepaper</a>
                                                <a class="badge badge-pill secondary-badge" :href="item"
                                                    v-for="(item, k) in response.social_media_link" :key="k">{{ k
                                                    }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <h6 class="">Project Introduction</h6>
                                    <p class="fs-14">{{ response.project_summary }}</p>
                                </div>
                                <div class="col-12">
                                    <h6 class="">Highlights</h6>
                                    <p class="fs-14">{{ response.company_highlight }}</p>
                                </div>
                                <div class="col-12"
                                    v-if="Object.entries(response.token_allocation).length || Object.entries(response.token_economics).length">
                                    <h6 class="">Protocol Token Sale and Economics</h6>
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <tbody>
                                                <template v-if="response.token_allocation">
                                                    <tr v-for="(item, k) in response.token_allocation" :key="k">
                                                        <th class="text-end w-50 fw-600">{{ (k).toUpperCase() }}</th>
                                                        <td>{{ item }}%</td>
                                                    </tr>
                                                </template>
                                                <template v-if="response.token_economics">
                                                    <tr v-for="(item, k) in response.token_economics" :key="k">
                                                        <th class="text-end w-50 fw-600">{{ (k).toUpperCase() }}</th>
                                                        <td>{{ item }}</td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-12"
                                    v-if="Object.entries(response.use_of_funds).length || Object.entries(response.fund_raising).length">
                                    <h6 class="">Usage of Funds and Raising</h6>
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <tbody>
                                                <template v-if="response.use_of_funds">
                                                    <tr v-for="(item, k) in response.use_of_funds" :key="k">
                                                        <th class="text-end w-50 fw-600">{{ (k).toUpperCase() }}</th>
                                                        <td>{{ item }}%</td>
                                                    </tr>
                                                </template>
                                                <template v-if="response.fund_raising">
                                                    <tr v-for="(item, k) in response.fund_raising" :key="k">
                                                        <th class="text-end w-50 fw-600">{{ (k).toUpperCase() }}</th>
                                                        <td>{{ item }}</td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <h6 class="">Disclaimer</h6>
                                    <p class="fs-14">{{ response.disclaimer }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <IeoComponent :mainData="response" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TopHeading from "@/components/ui/TopHeading.vue";
import Apiclass from '@/api/api';
import IeoComponent from '@/components/launchpad/IeoComponent.vue'
import { } from 'dom7';
export default {
    name: 'TokenView',
    components: {
        IeoComponent,
        TopHeading,
    },
    async created() {
        await this.tokenGet();
    },
    data() {
        return {
            topHeading: {
                heading: 'Launchpad',
                subheading: 'Token information get better visibility',
                // buttons: [
                //     // { name: 'Spot Wallet', link: '/wallet/spot', class: 'text-primary border-primary' },
                //     { name: 'Orders History', link: '/user/launchpad-orders', class: 'btn-primary text-white' }
                // ],
            },
            response: {},
            options: {
                year: "numeric",
                month: "long",
                day: "numeric",
            },
        };
    },
    methods: {
        async tokenGet() {
            // this.$route.params.slug
            let res = await Apiclass.getNodeRequest(`launchpad/getone/${this.$route.params.slug}`)
            this.response = res.data.data;
            //  console.log(this.response,"sdsds");
        }
    },
}
</script>

<style scoped>
table tbody th {
    font-weight: 600;
    color: var(--text-4);
    width: 50%;
}

#launchpad h6 {
    font-weight: 600;
    font-size: 20px;
}

table tbody td {
    /* font-weight: 400; */
    color: var(--text);
}

.sub-heading span {
    font-weight: 400;
    font-size: 14px;
    color: var(--text-2);
}

.invest-table p {
    font-weight: 600;
    font-size: 14px;
    color: var(--text-4);
}

.inactive {
    filter: opacity(0.5);
}


.success-badge {
    background-color: #CCFBF1;
}

.danger-badge {
    background-color: #FEE2E2;
}

.pending-badge {
    background-color: #ffdfa1;
}</style>

<template>
    <div class="card" id="launchpad_invest" v-if="mainData">
        <div class="card-body">
            <div class="row gy-2">
                <div class="col-12">
                    <div class="d-flex align-items-center gap-2">
                        <img :src="mainData.image" alt="image" class="border rounded-circle" width="30">
                        <p class="coin_name">{{ mainData.name }}</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="d-flex justify-content-between sub-heading border-bottom mb-2 py-1">
                        <span>IEO Round</span>
                        <span>IEO Price</span>
                    </div>
                    <div :class="!item.status ? 'inactive' : ''"
                        class="d-flex justify-content-between invest-table border-bottom mb-2 py-2"
                        v-for="(item, k) of mainData.launchpad_rounds[0].rounds" :key="k">
                        <div>
                            <p>Round {{ k + 1 }}</p>
                            <span class="fs-14">{{ new Date(item.expired_at).toLocaleDateString('en-US',
                                options) }}</span>
                        </div>
                        <p class="text-uppercase">{{ $helpers.NumberFixed(item.price, 2) }} {{ item.currency
                        }}</p>
                    </div>
                </div>
                <template v-if="loggedIn">
                    <form @submit.prevent="placeOrder" v-if="activeRound">
                        <div class="row gy-2">
                            <div class="col-12 fs-14">
                                <label for="basic-url" class="form-label">Amount</label>
                                <div class="input-group ">
                                    <input
                                        onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))"
                                        type="text" v-model="form.tokenAmount" class="form-control fs-14" id="basic-url"
                                        placeholder="Enter amount" />
                                </div>
                                <div class="error" v-if="submitted && !$v.form.tokenAmount.required">
                                    Amount is required
                                </div>
                                <div class="error" v-if="submitted && error">
                                    {{ error }}
                                </div>
                            </div>
                            <div class="col-12">
                                <label class="form-label fs-14">Currency</label>
                                <div class="input-group ">
                                    <select class="form-select fs-14" v-model="form.currency">
                                        <option selected>Select currency</option>
                                        <option :value="item.currency" v-for="(item, k) of currencyList" :key="k">{{
                                            item.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="error" v-if="submitted && !$v.form.currency.required">
                                    Currency is required
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="d-flex justify-content-between align-items-center fs-14">
                                    <span class="w-50">Minimum amount</span>
                                    <span class="fw-500">1 {{ mainData.symbol }}</span>
                                </div>
                                <div class="d-flex justify-content-between align-items-center fs-14">
                                    <span class="w-50">Amount to pay</span>
                                    <span class="fw-500">{{ getPayAmount() }}</span>
                                </div>
                            </div>
                            <div class="col-12">
                                <!-- loader  -->
                                <button v-if="loading" type="button" class="btn btn-primary w-100">
                                    <div class="spinner-border spinner-border-sm" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                                <!-- sign-btn  -->
                                <button v-else type="submit" class="btn btn-primary w-100">
                                    <span>Place Order</span>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="col-12" v-else>
                        <!-- sign-btn  -->
                        <button class="btn w-100 danger-badge border-0 py-2">
                            Crowdsale ended
                        </button>
                    </div>
                </template>
                <template v-else>
                    <div class="col-12">
                        <!-- sign-btn  -->
                        <router-link class="btn btn-primary w-100 text-white" to="/login">
                            <span>Login</span>
                        </router-link>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {
    required,
} from "vuelidate/lib/validators";
import Apiclass from '@/api/api';
export default {
    name: 'IeoComponent',
    props: {
        mainData: Object
    },
    data: () => ({
        error: null,
        loading: false,
        submitted: false,
        loggedIn: false,
        funds: [],
        zeroBalance: null,
        options: {
            year: "numeric",
            month: "long",
            day: "numeric",
        },
        currencyList: [
            { name: 'USDT', currency: 'USDT' }
        ],
        form: {
            launch_token_id: null,
            tokenAmount: null,
            currency: null,
            amount: null,
            launch_round_id: null,
        },
        activeRound: [],
    }),
    validations: {
        form: {
            currency: {
                required
            },
            tokenAmount: {
                required
            },
        }
    },
    methods: {
        getPayAmount() {
            let amount = this.$helpers.NumberFixed(this.activeRound.price * this.form.tokenAmount, 2);
            this.form.amount = amount;
            return amount;
        },
        getActiveRound() {
            let rounds = this.mainData?.launchpad_rounds[0]?.rounds.find((ele) => { return ele.status == true });
            this.activeRound = rounds;
            this.form.launch_token_id = this.mainData?.launchpad_rounds[0].launch_token_id;
            if (rounds) {
                this.form.launch_round_id = rounds.t_id;
                return rounds.price;
            }
        },
        async placeOrder() {
            this.submitted = true;
            if (this.$v.form.$invalid) {
        return;
      }
            this.zeroBalance = this.form.amount > parseFloat(this.funds.balance) ? true : false;
            if (this.zeroBalance) {
                this.error = "You Don't Have Sufficient Balance"
                return false;
            }

            this.loading = true;

            let response = await Apiclass.postNodeRequest('launchpad/order_place', true, this.form);
            if (response.data.status_code == 1) {
                this.$swal({
                    toast: true,
                    position: "top-end",
                    icon: "success",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3000,
                })

                this.form.tokenAmount = this.form.currency = this.form.amount = null

            } else {
                this.$swal({
                    toast: true,
                    position: "top-end",
                    icon: "error",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
            this.loading = false;
            this.submitted = false;
        },
        async getFunds() {
            let ress = await Apiclass.getNodeRequest("user-crypto/funds/get", true);
            let allFunds = ress.data.data || [];
            this.funds = allFunds.find((v) => { return v.currency == 'USDT' })
            if (this.funds.balance == '0') this.zeroBalance = true;
        },
    },
    async mounted() {
        await this.getActiveRound();
        if (localStorage.getItem("token")) {
            this.loggedIn = true;
            this.getFunds();
        }
    }

}
</script>
<style scoped>
table tbody th {
    font-weight: 600;
    color: var(--text-4);
    width: 50%;
}

#launchpad h6 {
    font-weight: 600;
    font-size: 20px;
}

table tbody td {
    /* font-weight: 400; */
    color: var(--text);
}

.sub-heading span {
    font-weight: 400;
    font-size: 14px;
    color: var(--text-2);
}

.invest-table p {
    font-weight: 600;
    font-size: 14px;
    color: var(--text-4);
}

.inactive {
    filter: opacity(0.5);
}

.success-badge {
    background-color: #CCFBF1;
}

.danger-badge {
    background-color: #FEE2E2;
}

.pending-badge {
    background-color: #ffdfa1;
}

#launchpad_invest h6 {
    font-weight: 600;
    font-size: 20px;
}
</style>